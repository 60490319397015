import React from 'react'
import './hero-row.css'

export default ({
    text,
    subtext
}) => {
    return (
      <div className="row row-hero">
        <div className="col-md-12">
          <h1>{text}</h1>
          <h5>{subtext}</h5>
        </div>      
      </div>
    )
}