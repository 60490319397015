import React from 'react'
import { Link } from 'gatsby'
import './navigation.css'
import {isMobile} from "react-device-detect";


export default () => {
  return(
    <nav role="navigation">
      <div className="navigation d-flex align-items-center justify-content-between">
        <div>
          <img style={{height:"75px", width:"100px"}} src={require('../images/hcs-notext.png')} alt="homecaresuites"></img>
        </div>
        <Buttons/>
      </div>
    </nav>
  )
}


const Buttons = () => {
  if(!isMobile){
    return(
      <div>
        <Link to="/" className="navigationItem">About</Link>
        <Link to="/floorplans" className="navigationItem">Floorplans</Link>
        <Link to="/portfolio" className="navigationItem">Portfolio</Link>
        {/* <Link to="/" className="navigationItem">Process</Link> */}
        <Link to="/contact" className="btn btn-primary">Contact</Link>
      </div>
    )
  }

  if(isMobile){
    return(
      <div className="dropdown">
        <button className="btn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img style={{height:"50px", width:"50px"}} src={require('../images/menu-24px.svg')} alt="nav"/>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <Link to="/" className="dropdown-item text-right">About</Link>
          <Link to="/floorplans" className="dropdown-item text-right">Floorplans</Link>
          <Link to="/portfolio" className="dropdown-item text-right">Portfolio</Link>
          {/* <Link to="/" className="dropdown-item text-right">Process</Link> */}
          <Link to="/contact" className="dropdown-item text-right">Contact</Link>
        </div>
      </div>
    )
  }
}