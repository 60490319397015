import React from 'react'
import './footer.css'
import { Link } from 'gatsby'

export default () => {
    return (
        <footer role="footer">
            <div className="d-flex flex-column align-items-center justify-content-center div-footer text-center">
                <div>
                    <p>Copyright © 2017. Home Care Suites, Inc.</p>
                    <Link to="/privacy">Privacy Policy</Link>
                </div>
                <div style={{marginTop:"75px"}} className="d-flex">
                    <p>Website by <a href="https://github.com/henrym6003">Henry Moseley &rarr;</a></p>
                </div>
            </div>
        </footer>
    )
}